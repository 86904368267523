import { graphql } from 'gatsby'
import React from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css" 
import Img from "gatsby-image" 

const RVasquePage = ({ data }) => { 
 
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    dots:true,
    draggable:true, 
  };

  const g1 = data.g1.edges.map(({ node }) => node.childImageSharp)
  const g2 = data.g2.edges.map(({ node }) => node.childImageSharp)
  const g3 = data.g3.edges.map(({ node }) => node.childImageSharp)
  const g = data.gallery.edges.map(({ node }) => node.childImageSharp)
 
  return(
      <Layout>
        <SEO title="Agencement" />
        <div class="w-full relative">
            <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
                <h1 class="text-center text-2xl mt-5 font-bold">Nos Réalisations Vasques</h1>
                <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
            </div>
            <div className="slider-wrapper">
              <Slider  {...settingsMain}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div> 
        </div>  
      <div class="grid grid-cols-1 mt-8 md:mx-40 md:my-5 mx-3">
          <p class="mb-3 mt-10">
          Découvrez tous nos produits Lavabo, vasques et plan vasque pour votre salle de bains.
         </p>
        <p class="mb-4">Nous assurons la création de différentes formes et design qui convient à votre imagination pour :</p>

         <ul class="list-disc pl-10">
            <li>Vasque à poser : carrée, ovale et rectangulaire</li>
            <li>Vasque à encastrer : carrée, ovale et rectangulaire</li>
            <li>Vasque Colonne</li>
            <li>Vasques convient à votre imagination</li>
        </ul>
      </div> 
      <div class="w-full gl">
          <Gallery images={g1} />
          <Gallery images={g2} />
          <Gallery images={g3} />
          <Gallery images={g} />
      </div>
      </Layout> 
 )
}
export const query = graphql`
  query RVasqueQuery {
    g1:allFile (filter:{relativeDirectory: {eq: "img/realisations/vasque/1"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    g2:allFile (filter:{relativeDirectory: {eq: "img/realisations/vasque/2"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    g3:allFile (filter:{relativeDirectory: {eq: "img/realisations/vasque/3"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    gallery:allFile (filter:{relativeDirectory: {eq: "img/realisations/vasque/r-g"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 270, maxHeight: 270) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    sld:allFile (filter:{relativeDirectory: {eq: "img/realisations/vasque/r-s"}}){
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 100) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 1024) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
export default RVasquePage